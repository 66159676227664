export default class SliderController {
    constructor() {
        this.swiper = null;
        this.sliders = [];
        this.breakpoints = {
            mobile: 0,
            tablet: 768,
            desktop: 1200,
            fullHD: 1920,
        };

        this.init();
    }

    init() {
        if (document.querySelectorAll('.swiper-container').length) {
            if (typeof Number.isNaN == 'undefined') {
                Number.isNaN = number => number === NaN;
            }

			import( /* webpackChunkName: "modules/slider" */ 'swiper').then(e => {
                this.swiper = e.Swiper;
                this.swiper.use([e.Navigation, e.Scrollbar, e.Pagination, e.Autoplay]);

                document.querySelectorAll('.swiper-container').forEach(container => {
                    let settings = {
                        spaceBetween: 40,
                        grabCursor: true,
                        threshold: 10,
                        navigation: {
                            nextEl: container.parentElement.querySelector('.swiper-button-next'),
                            prevEl: container.parentElement.querySelector('.swiper-button-prev'),
                        },
                        scrollbar: {
                            el: container.parentElement.querySelector('.swiper-scrollbar'),
                            draggable: true,
                            hide: false,
                        },
                        mousewheel: {
                            forceToAxis: true,
                        },
                        on: {},
                    };

                    if (container.parentElement.querySelector('.swiper-pagination')) {
                        if (container.querySelectorAll('.swiper-slide').length > 1) {
                            settings.pagination = {
                                el: container.parentElement.querySelector('.swiper-pagination'),
                                clickable: true,
                            };
                        } else {
                            container.parentElement.querySelector('.swiper-pagination').remove();
                            settings.allowSlideNext = false;
                            settings.allowSlidePrev = false;
                            settings.allowTouchMove = false;
                        }
                    }

                    settings = Object.assign(settings, this.getSpecificOptions(container));

                    if (container.parentElement.querySelector('.indicator')) {
                        settings.on.sliderMove = () => {
                            container.parentElement.querySelector('.indicator').classList.add('hidden');
                        };
                    }

                    const slider = new this.swiper(container, settings);

                    this.sliders.push(slider);
                    
                    // Eventlisteners overlay carousel
                    if (container.parentElement.querySelector('.simpleCarousel .fullscreenButton')) {
                        container.parentElement.querySelector('.simpleCarousel .fullscreenButton').addEventListener('click', () => {
                            container.parentElement.classList.add('overlayActive');
                        })
                    }

                    if(container.parentElement.querySelector('.overlayCarousel')) {
                        if(container.parentElement.querySelector('.overlayCarousel .close')){
                            container.parentElement.querySelector('.overlayCarousel .close').addEventListener('click', () => {
                                container.parentElement.classList.remove('overlayActive');
                            }) 
                        } 

                        if(container.parentElement.querySelector('.simpleCarousel')){
                            console.log(container, container.parentElement, container.parentElement.querySelector('.overlayCarousel'));
                            const nextBtn = container.closest('.sliderWrapper').querySelector('.swiper-button-next');
                            const prevBtn = container.closest('.sliderWrapper').querySelector('.swiper-button-prev');

                            const dummyNextBtn = container.parentElement.querySelector('.overlayCarousel .next');
                            const dummyPrevBtn = container.parentElement.querySelector('.overlayCarousel .previous');

                            if(dummyNextBtn && dummyPrevBtn){
                                dummyNextBtn.addEventListener('click', () => {nextBtn.click();});
                                dummyPrevBtn.addEventListener('click', () => {prevBtn.click();});
                            }

                            
                        }
                    }    
                });
			});
		}
    }

    getSpecificOptions(container) {
        let settings = {
            breakpoints: {},
        };

        settings.breakpoints[this.breakpoints.mobile] = {
            spaceBetween: 20,
            slidesPerView: 1,
        };

        settings.breakpoints[this.breakpoints.tablet] = {
            spaceBetween: 20,
            slidesPerView: 1.2,
        };

        settings.breakpoints[this.breakpoints.desktop] = {
            spaceBetween: 20,
            slidesPerView: 2,
        };

        settings.breakpoints[this.breakpoints.fullHD] = {
            spaceBetween: 20,
            slidesPerView: 2.5,
        };

         if (container.classList.contains('newsHighLightsList')) {
             settings.breakpoints[this.breakpoints.mobile] = {
                 slidesPerView: 1,
             };
             settings.breakpoints[this.breakpoints.tablet] = {
                 slidesPerView: 2,
             };
             settings.breakpoints[this.breakpoints.desktop] = {
                 slidesPerView: 3,
             };
             settings.breakpoints[this.breakpoints.fullHD] = {
                 slidesPerView: 3,
             };
             settings.spaceBetween = 30;
         }

         if (container.classList.contains('navigationCardList')) {
             settings.breakpoints[this.breakpoints.mobile] = {
                 slidesPerView: 1.1,
             };
             settings.breakpoints[this.breakpoints.tablet] = {
                 slidesPerView: 3,
             };
             settings.breakpoints[this.breakpoints.desktop] = {
                 slidesPerView: 4,
             };
             settings.breakpoints[this.breakpoints.fullHD] = {
                 slidesPerView: 4,
             };
             settings.spaceBetween = 30;
         }

          if (container.classList.contains('carousalBlocksList')) {
              settings.breakpoints[this.breakpoints.mobile] = {
                  slidesPerView: 1.2,
              };
              settings.breakpoints[this.breakpoints.tablet] = {
                  slidesPerView: 2,
              };
              settings.breakpoints[this.breakpoints.desktop] = {
                  slidesPerView: 3,
              };
              settings.breakpoints[this.breakpoints.fullHD] = {
                  slidesPerView: 3,
              };
              settings.spaceBetween = 30;
          };

          if (container.classList.contains('heroBannerList')) {
            console.log(container.parentElement.querySelector(
                      '.swiper-button-next'
            ))
              settings.breakpoints = [];
              settings.spaceBetween = 0;
              settings.slidesPerView = 1;
              settings.autoplay = {
                  delay: 10000,
              };
              settings.speed = 1000;
              settings.navigation = {
                  nextEl: container.parentElement.querySelector(
                      '.swiper-button-next'
                  ),
                  prevEl: container.parentElement.querySelector(
                      '.swiper-button-prev'
                  ),
              };
          }
          if (container.classList.contains('simpleCarousel')) {
                settings.breakpoints = [];
                settings.spaceBetween = 0; 
                settings.slidesPerView = 1;
                settings.loop = true;
                settings.speed = 300; 
                settings.allowTouchMove = false;
                settings.navigation = {
                    nextEl: container.closest('.sliderWrapper').querySelector(
                        '.swiper-button-next'
                    ),
                    prevEl: container.closest('.sliderWrapper').querySelector(
                        '.swiper-button-prev'
                    ),
                };
          }
         
          if (container.classList.contains('overlayCarousel')) {
                settings.breakpoints = [];
                settings.spaceBetween = 0; 
                settings.slidesPerView = 1;
                settings.loop = true;
                settings.speed = 300; 
                settings.allowTouchMove = false;
                settings.navigation = {
                    nextEl: container.closest('.sliderWrapper').querySelector(
                        '.swiper-button-next'
                    ),
                    prevEl: container.closest('.sliderWrapper').querySelector(
                        '.swiper-button-prev'
                    ),
                };
        }

        return settings;
    }
}

document.addEventListener('DOMContentLoaded', () => {
    window.SliderController = new SliderController();
});