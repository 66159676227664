function embedIframe(url, alt) {
    return '<div class="video"><iframe src="' + url + '" width="100%" height="100%" frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen title="YouTube video ' + alt + '"></iframe></div>';
}

$(document).ready(function () {
    $('noscript').each(function () {
        var media = this;

        switch ($(media).data('mediatype')) {
            case "youtube":
                var url = "https://www.youtube.com/embed/" + $(media).data('youtube') + "?wmode=opaque&disablekb=1";
                var embed = embedIframe(url,$(media).data('alt'));
                $(media).after(embed);
                $(media).remove();
                break;
        }
    });
})